/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    span: "span"
  }, _provideComponents(), props.components), {TextBlock} = _components;
  if (!TextBlock) _missingMdxReference("TextBlock", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Before you pay your next car insurance premium you need to see this..."), "\n", React.createElement(_components.p, null, "There is a new smart comparison system that most drivers are not aware of, because of that they are probably overpaying for their car insurance. This system can reduce your premiums significantly."), "\n", React.createElement(TextBlock, {
    slug: "car-insurance-image-link"
  }), "\n", React.createElement(_components.h3, null, "AS SEEN ON"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 1113px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 15.974440894568689%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApklEQVR42j2POw6DMBBEuS4n4CZwFToOkNCAQuE0JkWELVFQYPyfeDdKVppiP6N5W+WccVoH4zyM9YgpIRdd1wVrLSuVnmpdVwghsG0byEelteZeKcV3VUoZs3zjJiTG5wvWRzjnME8TlseC8T7iOA42D8OAtm3R9z1ijDzrug51XaNpGoaoaBjK0oUAX4RvMBNQIhl/hMYYSCn/hPzdeWLfdyYlwg/KJuBI7uN6cgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <picture>\n          <source\n              srcset=\"/static/ff2169a988771fa9d6baf5986244133e/2a8c3/media-logo-no-msn.webp 313w,\n/static/ff2169a988771fa9d6baf5986244133e/ee9b0/media-logo-no-msn.webp 625w,\n/static/ff2169a988771fa9d6baf5986244133e/174bc/media-logo-no-msn.webp 1113w\"\n              sizes=\"(max-width: 1113px) 100vw, 1113px\"\n              type=\"image/webp\"\n            />\n          <source\n            srcset=\"/static/ff2169a988771fa9d6baf5986244133e/7e87c/media-logo-no-msn.png 313w,\n/static/ff2169a988771fa9d6baf5986244133e/5eb1c/media-logo-no-msn.png 625w,\n/static/ff2169a988771fa9d6baf5986244133e/9db08/media-logo-no-msn.png 1113w\"\n            sizes=\"(max-width: 1113px) 100vw, 1113px\"\n            type=\"image/png\"\n          />\n          <img\n            class=\"gatsby-resp-image-image\"\n            src=\"/static/ff2169a988771fa9d6baf5986244133e/9db08/media-logo-no-msn.png\"\n            alt=\"media logo no msn\"\n            title=\"\"\n            loading=\"lazy\"\n            decoding=\"async\"\n            style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n          />\n        </picture>\n    </span>"
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
